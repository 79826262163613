.gobackPrompt {
    color: white !important;
    height: 10vh;
    margin-left: 10px;
}
.goback {
    height: 4vw;
    width: 4vw;
    margin-left: 10px;
    margin-top: 10px;
}

.btmBtn{
    color: white;
    height: 10vh;   
}

.subDiv{
    background-color: #2679E4;
}

.subHeadingText{
    font-size:"1.5vw"; 
    color: white
 }

.banner{
    margin-top: 12% !important;
}

 .bannerText{
    font-size:2.3vw;
    color:white;
    margin-top: 10%;
    margin-left: 5%;
    text-align: left;
    font-weight: bold;
    
 }

 .btnText{
    font-size: 1rem;
    font-weight: bold;
    color: white;
 }
 .small-btnText{
    font-size: .7rem;
    font-weight: bold;
    color: white;
    text-align: center;
 }

 @media (min-width: 0px) and (max-width: 575.98px) { 
    .btmBtn{
        color: white;
        height: 10vh;
        margin-top: 40px !important;
    }

    .subHeadingText{
        font-size: .85rem; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-top: 20px;     
    }

    .bannerText{
        font-size: .7rem;
        color:white;
        margin-top: 10px;
        text-align: left;
        line-height: 16px;
        font-weight: bold;
     }

     .banner{
        margin-top: 20% !important;
     }

     .btnText{
        font-size: 1rem;
        font-weight: 600;
     }
     .small-btnText{
        font-size: .6rem;
        font-weight: bold;
        color: white;
        text-align: center;
     }

     .gobackPrompt {
        font-size: 2rem;
    }
   

 }

 @media (min-width: 576px) and (max-width: 767.98px) { 
    .btmBtn{
        color: white;
        height: 10vh;
        margin-top: 40px !important;
    }


    .subHeadingText{
        font-size: 1rem; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-top: 20px;     
    }

    .bannerText{
        font-size: 1rem;
        color:white;
        margin-top: 25px;
        text-align: left;
        font-weight: bold;
     }

     .banner{
        margin-top: 20% !important;
     }

     .gobackPrompt {
        font-size: 1rem;
    }
    
}

@media (min-width: 768px) and (max-width:991.98px) { 
    .btmBtn{
        color: white;
        height: 10vh;
        margin-top: 40px !important;
    }

    .subHeadingText{
        font-size: 1.25rem; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-top: 20px;     
    }

    .bannerText{
        font-size:1.25rem;
        color:white;
        margin-top: 25px;
        text-align: left;
        font-weight: bold;
     }
     .btnText{
        font-size: 1.25rem;
        font-weight: 600;
     }
     .small-btnText{
        font-size: 1rem;
        font-weight: bold;
        color: white;
        text-align: center;
     }

     .banner{
        margin-top: 20% !important;
     }
     .gobackPrompt {
        font-size: 1rem;
    }
    .goback {
        height: 4.5vw !important;
        width: 4.5vw !important;
    }

}

@media (min-width: 992px) and (max-width:1199.98px) { 
    .subHeadingText{
        font-size: 25px; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-bottom: 5%;    
    }
    .btnText{
        font-size:1.8rem;
        font-weight: 600;
     }
     .small-btnText{
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
        text-align: center;
     }

     .bannerText{
        font-size:22px;
        color:white;
        margin-top: 10%;
        margin-left: 5%;
        text-align: left;
        font-weight: bold;
     }

     .gobackPrompt {
        font-size: 1.5rem;
    }

    .goback {
        height: 4vw !important;
        width: 4vw !important;
    }
}

@media (min-width: 1200px) and (max-width:1399.98px) { 
    .subHeadingText{
        font-size: 25px; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-bottom: 4%;
        margin-top: -3%;     
    }
    .btnText{
        font-size: 1.75rem;
        font-weight: 600;
     }

     .small-btnText{
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
        text-align: center;
     }

     .bannerText{
        font-size: 2rem;
        color:white;
        margin-top: 10%;
        margin-left: 5%;
        text-align: left;
        font-weight: bold;
     }

     .gobackPrompt {
        font-size: 1.5rem;
    }
    .goback {
        height: 3vw !important;
        width: 3vw !important;
    }

    
    
}

@media (min-width: 1400px) and (max-width:1599.98px){
    .subHeadingText{
        font-size: 28px; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-bottom: 2%;     
    }

    .btnText{
        font-size: 1.65rem;
        font-weight: 600;
     }
     .bannerText{
        font-size: 2rem;
        color:white;
        margin-top: 10%;
        margin-left: 5%;
        text-align: left;
        font-weight: bold;
     }
     .gobackPrompt {
        font-size: 2rem;
    }
    .goback {
        height: 4vw !important;
        width: 4vw !important;
    }
}


@media (min-width: 1600px){
    .subHeadingText{
        font-size: 35px; 
        color: white;
        word-spacing: 5px;
        font-weight: bold;
        margin-top: 4%;
        margin-bottom: 4%;     
    }

    .btnText{
        font-size: 1.75rem;
        font-weight: 600;
     }

     .bannerText{
        font-size: 2rem;
        color:white;
        margin-top: 10%;
        margin-left: 5%;
        text-align: left;
        font-weight: bold;
     }
     .gobackPrompt {
        font-size: 2rem;
    }
    .goback {
        
        height: 2vw !important;
        width: 2vw !important;
    }
    
}

